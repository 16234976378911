import { Theme, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactNode } from 'react'

import { currentSite } from '../sites'
import { getTheme } from '../themes/themeProvider'

const theme = getTheme(currentSite)

interface Props {
  children: ReactNode
  theme?: Theme
}

const ThemeProvider = ({ children, theme: customTheme }: Props) => (
  <EmotionThemeProvider theme={customTheme || theme}>
    {children}
  </EmotionThemeProvider>
)

export default ThemeProvider
