import { defaultTheme } from '../theme'
import fonts from './fonts'
import * as text from './text'

export const yachtSupportTheme = {
  ...defaultTheme,
  text,
  fonts,
  shadows: {
    sm: 'box-shadow: 0px 1px 3px 0px rgba(34, 41, 44, 0.10), 0px 2px 10px 0px rgba(34, 41, 44, 0.05);',
    md: 'box-shadow: 0px 5px 12px 0px rgba(34, 41, 44, 0.10);',
    lg: 'box-shadow: 10px 20px 40px 0px rgba(34, 41, 44, 0.10);',
    xl: 'box-shadow: 20px 40px 60px 0px rgba(34, 41, 44, 0.10);',
  },
  site: {
    name: 'yachtsupport',
  },
} as const

export type YachtSupportTheme = typeof yachtSupportTheme
