import { useRouter } from 'next/router'
import { useEffect } from 'react'

type Props = {
  onRouteComplete: (url: string) => void
  triggerOnLoad?: boolean
}
export const useOnRouteChangeComplete = ({
  onRouteComplete,
  triggerOnLoad = false,
}: Props) => {
  const router = useRouter()

  useEffect(() => {
    if (triggerOnLoad) {
      onRouteComplete(router.asPath)
    }
    // No args here, as it should only run once ever
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    router.events.on('routeChangeComplete', onRouteComplete)

    return () => {
      router.events.off('routeChangeComplete', onRouteComplete)
    }
  }, [onRouteComplete, router])
}
