import { Theme } from '@emotion/react'
import { AmelsTheme } from './amels/theme'
import { YachtingTheme } from './yachting/theme'
import { XplorerTheme } from './xplorer/theme'
import { LandingspageTheme } from './landingspage/theme'
import { WomenInYachtingPageTheme } from './womenInYachtingPage/theme'
import { YachtSupportTheme } from './yachtsupport/theme'

export const isSiteYachting = (theme: Theme): theme is YachtingTheme =>
  theme.site.name === 'yachting' ||
  theme.site.name === 'yachting-womenInYachtingLandingspage'

export const isSiteAmels = (theme: Theme): theme is AmelsTheme =>
  theme.site.name === 'amels' || theme.site.name === 'amels-landingspage'

export const isSiteXplorer = (theme: Theme): theme is XplorerTheme =>
  theme.site.name === 'xplorer'

export const isThemeAmelsLandingspage = (
  theme: Theme,
): theme is LandingspageTheme => theme.site.name === 'amels-landingspage'

export const isThemeYachtingWomenInYachtingLandingspage = (
  theme: Theme,
): theme is WomenInYachtingPageTheme =>
  theme.site.name === 'yachting-womenInYachtingLandingspage'

export const isSiteYachtSupport = (theme: Theme): theme is YachtSupportTheme =>
  theme.site.name === 'yachtsupport'
