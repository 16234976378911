import { Theme, css } from '@emotion/react'

export const heading1 = (theme: Theme) =>
  css`
    font-family: ${theme.fonts.Chaney.style.fontFamily};
    font-size: 28px;
    font-weight: 400;
    line-height: 1.1;
    color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 60px;
      line-height: 1.2;
    }
  `

export const heading1Light = () => css``

export const heading2 = (theme: Theme) =>
  css`
    font-family: ${theme.fonts.Chaney.style.fontFamily};
    font-size: 24px;
    font-weight: 400;
    line-height: 1.1;
    color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 40px;
      line-height: 1.2;
    }
  `

export const heading3 = (theme: Theme, colorScheme?: 'light' | 'dark') =>
  css`
    font-family: ${theme.fonts.TTFirsNeue.style.fontFamily};
    font-size: 22px;
    font-weight: 400;
    line-height: 1.1;
    color: ${colorScheme === 'dark'
      ? theme.colors.xplorer.white
      : theme.colors.xplorer.primaryXPDarkOceanGreen};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 36px;
      line-height: 1.2;
    }
  `

export const heading4 = (theme: Theme) =>
  css`
    font-family: ${theme.fonts.TTFirsNeue.style.fontFamily};
    font-size: 20px;
    font-weight: 400;
    line-height: 1.1;
    color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 24px;
      line-height: 1.2;
    }
  `

export const subHeading = () => css``

export const subHeadingWide = () => css``

export const heading5 = (theme: Theme) =>
  css`
    font-family: ${theme.fonts.TTFirsNeue.style.fontFamily};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.1;
    color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};
    text-transform: uppercase;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 16px;
      line-height: 1.2;
    }
  `

export const heading6 = (theme: Theme) =>
  css`
    font-family: ${theme.fonts.TTFirsNeue.style.fontFamily};
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1;
    color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 16px;
      line-height: 1.2;
    }
  `

export const body = (theme: Theme) => css`
  font-family: ${theme.fonts.TTFirsNeue.style.fontFamily};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
`

export const bodySmall = (theme: Theme) => css`
  ${body(theme)}
  font-size: 14px;
  letter-spacing: 0.2px;
`

export const bodyLarge = (theme: Theme) => css`
  ${body(theme)}
  font-size: 18px;
`

export const overlineMedium = (theme: Theme) =>
  css`
    font-family: ${theme.fonts.TTFirsNeue.style.fontFamily};
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  `

export const overlineSmall = (theme: Theme) =>
  css`
    ${overlineMedium(theme)}
    font-size: 14px;
  `
