import { Theme, css } from '@emotion/react'
import type { NextFont } from 'next/dist/compiled/@next/font'

const getFontFamily = (nextFont: NextFont) => {
  return nextFont?.style.fontFamily ?? 'serif'
}

export const heading1 = (theme: Theme) =>
  css`
    color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
    font-family: ${getFontFamily(theme.fonts.Orbitron)};
    font-size: 36px;
    font-weight: 900;
    line-height: 1.1;
    text-transform: uppercase;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 60px;
      line-height: 1.2;
    }
  `

export const heading1Light = () => css``

export const heading2 = (theme: Theme, colorScheme?: 'light' | 'dark') =>
  css`
    font-family: ${getFontFamily(theme.fonts.Orbitron)};
    font-size: 28px;
    font-weight: 500;
    line-height: 1.1;
    color: ${colorScheme === 'dark'
      ? theme.colors.yachtSupport.white
      : theme.colors.yachtSupport.primaryYSOceanBlue};

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 44px;
      line-height: 1.2;
      letter-spacing: 2px;
    }
  `

export const heading3 = (theme: Theme) =>
  css`
    color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
    font-family: ${getFontFamily(theme.fonts.Orbitron)};
    font-size: 22px;
    font-weight: 900;
    line-height: 1.1;
    text-transform: uppercase;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 28px;
      line-height: 1.2;
    }
  `

export const heading4 = (theme: Theme) =>
  css`
    color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
    font-family: ${getFontFamily(theme.fonts.Orbitron)};
    font-size: 20px;
    font-weight: 500;
    line-height: 1.1;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 24px;
      line-height: 1.2;
    }
  `

export const subHeading = () => css``

export const subHeadingWide = () => css``

export const heading5 = (theme: Theme) =>
  css`
    color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
    font-family: ${getFontFamily(theme.fonts.Orbitron)};
    font-size: 18px;
    font-weight: 500;
    line-height: 1.1;
    text-transform: uppercase;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      line-height: 1.2;
    }
  `

export const heading6 = (theme: Theme) =>
  css`
    color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
    font-family: ${getFontFamily(theme.fonts.Orbitron)};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.1;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      line-height: 1.2;
    }
  `

export const body = (theme: Theme) =>
  css`
    color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
    font-family: ${getFontFamily(theme.fonts.Rubik)};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  `

export const bodySmall = (theme: Theme) =>
  css`
    ${body(theme)}
    font-size: 14px;
    letter-spacing: 0.2px;
  `

export const bodyLarge = (theme: Theme) =>
  css`
    ${body(theme)}
    font-size: 18px;
  `

export const bodyBold = (theme: Theme) =>
  css`
    color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
    font-family: ${getFontFamily(theme.fonts.Rubik)};
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  `

export const bodyBoldSmall = (theme: Theme) =>
  css`
    ${body(theme)}
    font-size: 14px;
    letter-spacing: 0.2px;
  `

export const bodyBoldLarge = (theme: Theme) =>
  css`
    ${body(theme)}
    font-size: 18px;
  `

export const overLineSmall = (theme: Theme) =>
  css`
    color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
    font-family: ${getFontFamily(theme.fonts.Rubik)};
    font-size: 12px;
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: 0.24px;
    text-transform: uppercase;
  `

export const overLineMedium = (theme: Theme) =>
  css`
    color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
    font-family: ${getFontFamily(theme.fonts.Rubik)};
    font-size: 14px;
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: 0.28px;
    text-transform: uppercase;
  `
