import { Theme, css } from '@emotion/react'

export const heading1 = (theme: Theme) =>
  css`
    font-family: ${theme.fonts.Georgia.style.fontFamily};
    font-size: 44px;
    font-weight: 400;
    line-height: 1.2;
    color: ${theme.colors.yachting.darkBlue};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 54px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 80px;
    }
  `
export const heading1Light = (theme: Theme) => css`
  ${heading1(theme)}
`
export const heading2 = (theme: Theme) =>
  css`
    font-family: ${theme.fonts.Georgia.style.fontFamily};
    font-size: ${theme.spacing.x5}px;
    font-weight: 400;
    line-height: 1.2;
    color: ${theme.colors.yachting.darkBlue};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: ${theme.spacing.x6}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 60px;
    }
  `
export const heading3 = (theme: Theme) =>
  css`
    font-family: ${theme.fonts.Georgia.style.fontFamily};
    font-size: 26px;
    font-weight: 400;
    line-height: 1.2;
    color: ${theme.colors.yachting.darkBlue};

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 30px;
    }
  `
export const heading3Regular = (theme: Theme) =>
  css`
    ${heading3(theme)}
    font-weight: 400;
  `
export const heading4 = (theme: Theme) =>
  css`
    font-family: ${theme.fonts.Georgia.style.fontFamily}, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    color: ${theme.colors.yachting.darkBlue};

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 20px;
    }
  `
export const subHeading = (theme: Theme) => css`
  font-family: ${theme.fonts.Georgia.style.fontFamily}, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  color: ${theme.colors.yachting.darkBlue};

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 20px;
  }
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const subHeadingWide = (theme: Theme) => css`
  // TODO
`
export const body = (theme: Theme) => css`
  font-family: ${theme.fonts.HelveticaNeue.style.fontFamily}, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.colors.yachting.darkBlue};

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 20px;
  }
`
export const bodyLargeIntroduction = (theme: Theme) => css`
  font-size: 20px;
  font-weight: 300;

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 22px;
  }
`
export const overlineMedium = (theme: Theme) =>
  css`
    font-family: ${theme.fonts.HelveticaNeue.style.fontFamily}, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: ${theme.colors.yachting.darkBlue};
    text-transform: uppercase;
    letter-spacing: 5px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 18px;
    }
  `
export const overlineSmall = (theme: Theme) =>
  css`
    ${overlineMedium(theme)}
    letter-spacing: 5px;
    font-size: 12px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 14px;
    }
  `
