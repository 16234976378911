export interface DataLayer extends Array<object> {
  push(...items: object[]): number
}

declare global {
  interface Window {
    dataLayer: DataLayer
  }
}

const dataLayer: DataLayer =
  typeof window !== 'undefined'
    ? (window.dataLayer = window.dataLayer ?? [])
    : // Dummy array on the server to make our code simpler
      []

export default dataLayer
