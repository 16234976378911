import { defaultTheme } from '../theme'
import fonts from './fonts'
import * as text from './text'

export const yachtingTheme = {
  ...defaultTheme,
  text,
  fonts,
  shadows: {
    sm: 'box-shadow: 0px 0px 2px rgba(1, 23, 54, 0.12);',
    md: 'box-shadow: 0px 5px 12px rgba(1, 23, 54, 0.1);',
    lg: 'box-shadow: 10px 20px 40px rgba(1, 23, 54, 0.1);',
    xl: 'box-shadow: 20px 40px 60px rgba(1, 23, 54, 0.1);',
  },
  site: {
    name: 'yachting',
  },
} as const

export type YachtingTheme = typeof yachtingTheme
