import * as amels from './amels/colors'
import * as yachting from './yachting/colors'
import * as xplorer from './xplorer/colors'
import * as landingspage from './landingspage/colors'
import * as womenInYachtingPage from './womenInYachtingPage/colors'
import * as yachtSupport from './yachtsupport/colors'

/**
 * This is a workaround for colors in themes.
 *
 * Themes are supposed to be complete by themselves, but we can't generalize
 * colors. There's no single "primary" and "secondary" color; we have multiple
 * (e.g. orange and blue for Amels) and not every primary color is the same. For
 * example, often the "primary" color of one project is flashy (e.g. red) that
 * can't be used on large areas Other companies have primary color that's dark
 * (e.g. the dark blue) that can be used on large areas. Naming them the same
 * and acting like they are interchangeable would be wrong and much harder to
 * use for us.
 *
 * Red is a very hard color to use, as it quickly looks like something is wrong
 * (e.g. an error). You wouldn't use red as the color of your primary button. So
 * the primary/secondary color scheme is not scaleable to all projects.
 *
 * Since the UX team has decided to implement the Goldman Sachs design system
 * everywhere, that might hold the solution via color tokens; https://design.gs.com/foundation/color-system/color-tokens#overview.
 * But we will need to wait for them to implement it and update the designs
 * to use the correct tokens before we can use that.
 *
 * Until then, we just have a simple colors object where you pick the right
 * project. A big downside is having to do theme handling in many many
 * components, but I don't see a way around that.
 */
export {
  amels,
  yachting,
  xplorer,
  yachtSupport,
  landingspage,
  womenInYachtingPage,
}
