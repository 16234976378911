import { Site } from '../sites'
import { amelsTheme } from './amels/theme'
import { defaultTheme } from './theme'
import { xplorerTheme } from './xplorer/theme'
import { yachtingTheme } from './yachting/theme'
import { yachtSupportTheme } from './yachtsupport/theme'

// TODO: We can improve the loading of the site here by using dynamic imports (but this can not be async import?)
export const getTheme = (site: Site) => {
  switch (site) {
    case Site.Amels:
      return amelsTheme
    case Site.Yachting:
      return yachtingTheme
    case Site.Xplorer:
      return xplorerTheme
    case Site.YachtSupport:
      return yachtSupportTheme
    default:
      return defaultTheme
  }
}
