export const body = '#fff'
export const bodyText = '#191919'
export const silk = '#fcfaf8'
export const marble = '#faf7ee'
export const oyster = '#f4efdd'
export const shell = '#eddec1'
export const beach = '#c8a379'
export const deepBayAqua = '#00606f'
export const clearBayAqua = '#007b91'
export const hazySunsetOrange = '#f05f27'
export const deepBayAqua30 = '#b3cfd4'
export const deepBayAqua60 = '#669fa9'
export const bayGold = '#dfb979'
export const bayGold20 = '#f5ead7'
export const bayGold50 = '#ecd5af'
