// Primary
export const primaryXPDarkOceanGreen = '#004D45FF'
export const primaryXPLavaBlack = '#1D1D1BFF'
// Secondary
export const secondaryDamenYachtingOrange = '#EC7404FF'
export const secondaryDamenYachtingBeach = '#C8A379FF'
export const secondaryXPMistGrey = '#E8EAE5FF'
export const secondaryXPBronze = '#BA734EFF'
export const secondaryXPBronze10Extra = '#F5ECE4FF'

// XP Dark Ocean Green Tints
export const xpDarkOceanGreen70Solid = '#4D827DFF'
export const xpDarkOceanGreen30Solid = '#B2CAC7FF'
export const xpDarkOceanGreen10Solid = '#E5EDECFF'
export const xpDarkOceanGreen70 = '#004D45B3'
export const xpDarkOceanGreen80 = '#004D45CC'
export const xpDarkOceanGreen30 = '#004D454D'
export const xpDarkOceanGreen10 = '#004D451A'

// XP Lava Black Tints
export const xpLavaBlack70Solid = '#61615FFF'
export const xpLavaBlack30Solid = '#BBBBBBFF'
export const xpLavaBlack10Solid = '#E8E8E8FF'
export const xpLavaBlack70 = '#1D1D1BB3'
export const xpLavaBlack30 = '#1D1D1B4D'
export const xpLavaBlack10 = '#1D1D1B1A'

// XP Mist Grey Tints
export const xpMistGrey70Solid = '#EFF0EDFF'
export const xpMistGrey30Solid = '#F8F9F7FF'
export const xpMistGrey10Solid = '#FDFDFCFF'
export const xpMistGrey70 = '#E8EAE5B3'
export const xpMistGrey30 = '#E8EAE54D'
export const xpMistGrey10 = '#E8EAE51A'

// XP Bronze Tints
export const xpBronze70Solid = '#CF9D83FF'
export const xpBronze30Solid = '#EAD5CAFF'
export const xpBronze10Solid = '#F8F1EDFF'
export const xpBronze70 = '#BA734EB3'
export const xpBronze30 = '#BA734E4D'
export const xpBronze10 = '#BA734E1A'

// Neutrals
export const white = '#FFFFFFFF'
export const black = '#000000FF'

// Grey tints
export const grey01 = '#EEEEEEFF'
export const grey02 = '#DDDDDDFF'
export const grey03 = '#CCCCCCFF'
export const grey04 = '#BBBBBBFF'
export const grey05 = '#A3A3A3FF'

// Errors
export const error = '#FF2B2BFF'
