import localFont from 'next/font/local'

const TTFirsNeue = localFont({
  src: [
    {
      path: '../../assets/fonts/ttFirsNeue/ttFirsNeueRegular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/ttFirsNeue/ttFirsNeueDemiBold.woff2',
      weight: '600',
      style: 'normal',
    },
  ],
  variable: '--font-TTFirsNeue',
  display: 'swap',
  fallback: ['sans-serif'],
  preload: false,
})

const Chaney = localFont({
  src: [
    {
      path: '../../assets/fonts/chaney/chaney-ultraextended-webfont.woff2',
      weight: 'normal',
    },
  ],
  variable: '--font-Chaney',
  display: 'swap',
  fallback: ['sans-serif'],
  preload: false,
})

const fonts = { TTFirsNeue, Chaney }

export default fonts
