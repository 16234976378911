// darkBlue
export const darkBlue = '#08314C'

// marineBlue
export const marineBlue = '#143F5B'

// rockyGrey
export const rockyGrey = '#A6A7A9'

// terraBlush
export const terraBlush = '#D79778'

// apricotPeach
export const apricotPeach = '#ECBB9A'

// shell
export const shell = '#FAE3D2'

// pearlWhite
export const pearlWhite = '#FEF2E8'

// beach
export const beach10 = '#FAF6F2'

// neutrals
export const white = '#ffffff'
export const black = '#000000'
