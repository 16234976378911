import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import type { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ReactNode, useEffect, useRef } from 'react'
import type { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { useAppContext } from './RuntimeConfigProvider'

type Props = {
  children: ReactNode
}

const ApplicationInsightsProvider = ({ children }: Props) => {
  // We don't want to run this in the server as this module only supports the client
  // We can safely disable the rule of hooks as this will never hcange the render in this context
  /* eslint-disable react-hooks/rules-of-hooks */
  if (typeof window === 'undefined') {
    return children
  }

  const connectionString =
    useAppContext()?.publicRuntimeConfig
      ?.NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING

  const applicationInsightsRef = useRef<ApplicationInsights | null>(null)
  const reactPluginRef = useRef<ReactPlugin | null>()

  useEffect(() => {
    if (!connectionString) {
      return
    }

    if (applicationInsightsRef.current) {
      return
    }

    // Lazy load all Application Insights SDK's
    const loader = async () => {
      const { createBrowserHistory } = await import('history')
      const { ReactPlugin } = await import(
        '@microsoft/applicationinsights-react-js'
      )
      const { ApplicationInsights } = await import(
        '@microsoft/applicationinsights-web'
      )

      const browserHistory = createBrowserHistory({})

      reactPluginRef.current = new ReactPlugin()
      applicationInsightsRef.current = new ApplicationInsights({
        config: {
          connectionString,
          extensions: [reactPluginRef.current],
          extensionConfig: {
            [reactPluginRef.current.identifier]: { history: browserHistory },
          },
        },
      })
      applicationInsightsRef.current.loadAppInsights()
      // eslint-disable-next-line no-console
      console.log('Application Insights initialized')
    }
    loader()
  }, [connectionString])

  if (!reactPluginRef.current) {
    return children
  }

  return (
    <AppInsightsContext.Provider value={reactPluginRef.current}>
      {children}
    </AppInsightsContext.Provider>
  )
}

export default ApplicationInsightsProvider
