import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useMemo } from 'react'

import ApplicationInsightsProvider from '../components/ApplicationInsightsProvider'
import GlobalStyles from '../components/GlobalStyles'
import PortalRoot from '../components/PortalRoot'
import { AppContextProvider } from '../components/RuntimeConfigProvider'
import StoryblokPreviewBar from '../components/StoryblokPreviewBar'
import ThemeProvider from '../components/ThemeProvider'
import { useOnRouteChangeComplete } from '../utils/useOnRouteChangeComplete'
import dataLayer from '../utils/dataLayer'

const StoryblokBridgeLoader = dynamic(
  () => import('../components/StoryblokBridgeLoader'),
)

const App = ({
  Component,
  pageProps,
}: AppProps<{
  preview: boolean
  publicRuntimeConfig: Record<string, string>
}>) => {
  // Prevent passing whole object back to value Provider
  const publicRuntimeConfig = useMemo(() => {
    return {
      publicRuntimeConfig: pageProps.publicRuntimeConfig,
    }
  }, [pageProps.publicRuntimeConfig])

  useOnRouteChangeComplete({
    onRouteComplete: () => {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log({
          event: 'vpv',
          virtual_url: window.location.href,
          virtual_title: document.title,
        })
      }
      dataLayer.push({
        event: 'vpv',
        virtual_url: window.location.href,
        virtual_title: document.title,
      })
    },
    triggerOnLoad: true,
  })

  return (
    <ThemeProvider>
      <PortalRoot>
        <AppContextProvider value={publicRuntimeConfig}>
          <ApplicationInsightsProvider>
            <GlobalStyles />
            {pageProps?.preview && (
              <>
                <StoryblokBridgeLoader />
                <StoryblokPreviewBar />
              </>
            )}
            <Component {...pageProps} />
          </ApplicationInsightsProvider>
        </AppContextProvider>
      </PortalRoot>
    </ThemeProvider>
  )
}

export default App
