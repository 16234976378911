import type { NextFont } from 'next/dist/compiled/@next/font'
import * as colors from './colors'
import * as breakpoints from './breakpoints'
import * as spacing from './spacing'
import * as layout from './layout'
import * as text from './text'
import { ThemeText } from './types'

export const defaultTheme = {
  colors,
  breakpoints,
  spacing,
  layout,
  fonts: {} as Record<string, NextFont>,
  text: text as unknown as ThemeText,
  shadows: {} as Record<string, string>,
  site: {
    name: 'default',
  },
} as const

export type DefaultTheme = typeof defaultTheme
