// darkBlue
export const darkBlue = '#07314B'
export const darkBlue10 = '#E2E5EC'
export const darkBlue30 = '#ACB5C7'
export const darkBlue70 = '#4F637E'

// silver
export const silver = '#A6A7A8'
export const silver10 = '#F6F6F6'
export const silver30 = '#E4E5E5'
export const silver70 = '#C1C1C2'

// orange
export const orange = '#EE7418'
export const orange10 = '#FCF3EA'
export const orange30 = '#F7DABF'
export const orange70 = '#E9A56A'

// beach
export const beach = '#c8a379'
export const beach10 = '#F9F6F2'
export const beach30 = '#ECE3D7'
export const beach70 = '#D4BFA3'

// neutrals
export const white = '#ffffff'
export const black = '#000000'
