import { defaultTheme } from '../theme'
import fonts from './fonts'
import * as text from './text'

export const amelsTheme = {
  ...defaultTheme,
  text,
  fonts,
  site: {
    name: 'amels',
  },
} as const

export type AmelsTheme = typeof amelsTheme
