import { css, Theme } from '@emotion/react'

export const heading1 = (theme: Theme) => css`
  font-family: ${theme.fonts.Zermatt.style.fontFamily};
  font-size: 34px;
  font-weight: 300;
  line-height: 1.1;
  color: ${theme.colors.amels.deepBayAqua};

  @media screen and (min-width: ${theme.breakpoints.mobileMedium}px) {
    font-size: ${theme.spacing.x7}px;
  }

  @media screen and (min-width: ${theme.breakpoints.tablet}px) {
    font-size: ${theme.spacing.x10}px;
  }
`
export const heading1Light = (theme: Theme) => css`
  ${theme.text.heading1(theme)}
  color: ${theme.colors.amels.silk};
`
export const heading2 = (theme: Theme) => css`
  font-family: ${theme.fonts.Zermatt.style.fontFamily};
  font-size: ${theme.spacing.x4}px;
  font-weight: 300;
  line-height: 1.1;
  color: ${theme.colors.amels.deepBayAqua};

  @media screen and (min-width: ${theme.breakpoints.mobileMedium}px) {
    font-size: ${theme.spacing.x6}px;
  }

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: ${theme.spacing.x8}px;
  }
`
export const heading3 = (theme: Theme) => css`
  font-family: ${theme.fonts.Zermatt.style.fontFamily};
  font-size: 30px;
  font-weight: 300;
  line-height: 1.1;
  color: ${theme.colors.amels.deepBayAqua};

  @media screen and (min-width: ${theme.breakpoints.mobileMedium}px) {
    font-size: ${theme.spacing.x4}px;
  }

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: ${theme.spacing.x6}px;
  }
`
// TODO: Implement. Is this a sub heading?
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const heading4 = (theme: Theme) => css``
export const subHeading = (theme: Theme) => css`
  font-family: ${theme.fonts.Poppins.style.fontFamily};
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 9px;
  color: ${theme.colors.amels.beach};
  text-transform: uppercase;

  @media screen and (min-width: ${theme.breakpoints.desktop}px) {
    font-size: 18px;
    letter-spacing: 12px;
  }
`
export const subHeadingWide = (theme: Theme) => css`
  ${theme.text.subHeading ? theme.text.subHeading(theme) : ''};

  @media screen and (min-width: ${theme.breakpoints.tablet}px) {
    letter-spacing: 16px;
  }
`
export const body = (theme: Theme) => css`
  font-size: 14px;
  font-family: ${theme.fonts.Poppins.style.fontFamily}, sans-serif;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 1px;
  color: ${theme.colors.amels.bodyText};

  @media screen and (min-width: ${theme.breakpoints.tablet}px) {
    font-size: 16px;
  }
`
