// Primary
export const primaryYSOceanBlue = '#004771FF'
export const primaryYSCarbonBlack = '#22292CFF'

// Secondary
export const secondaryYSCoralGreen = '#589080FF'
export const secondaryYSWarmGrey = '#5C686DFF'
export const secondaryDamenYachtingOrange = '#EC7404FF'
export const secondaryDamenYachtingBeach = '#C8A379FF'

// YS Ocean Blue Tints
export const ysOceanBlue70Solid = '#4D7E9CFF'
export const ysOceanBlue30Solid = '#B2C8D4FF'
export const ysOceanBlue10Solid = '#E5EDF1FF'
export const ysOceanBlue80 = '#004771CC'
export const ysOceanBlue70 = '#004771B2'
export const ysOceanBlue30 = '#0047714D'
export const ysOceanBlue10 = '#0047711A'

// YS Carbon Black Tints
export const ysCarbonBlack70Solid = '#64696BFF'
export const ysCarbonBlack30Solid = '#BDBFC0FF'
export const ysCarbonBlack10Solid = '#E9EAEAFF'
export const ysCarbonBlack70 = '#22292CB2'
export const ysCarbonBlack30 = '#22292C4D'
export const ysCarbonBlack10 = '#22292C1A'

// YS Coral Green Tints
export const ysCoralGreen70Solid = '#8AB1A6FF'
export const ysCoralGreen30Solid = '#CDDED9FF'
export const ysCoralGreen10Solid = '#EEF4F2FF'
export const ysCoralGreen70 = '#589080B2'
export const ysCoralGreen30 = '#5890804D'
export const ysCoralGreen10 = '#5890801A'

// YS Warm Grey Tints
export const ysWarmGrey70Solid = '#8D9599FF'
export const ysWarmGrey30Solid = '#CED2D3FF'
export const ysWarmGrey10Solid = '#EFF0F0FF'
export const ysWarmGrey70 = '#5C686DB2'
export const ysWarmGrey30 = '#5C686D4D'
export const ysWarmGrey10 = '#5C686D1A'

// Neutrals
export const white = '#FFFFFFFF'
export const black = '#000000FF'

// Extra Grey tints
export const grey01 = '#EEEEEEFF'
export const grey02 = '#DDDDDDFF'
export const grey03 = '#CDD0D2FF'
export const grey04 = '#BBBBBBFF'
export const grey05 = '#A3A3A3FF'
